*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 110%;
  font-family: "Open Sans";
}

body {
  color: #ffffff;
  background-color: #141414;
}
a, button /* &c... */ {
  all: unset;
}

ul {
  list-style-type: none;
}
input {
  border-bottom: 0.2px solid #ffffff;
  border-top: none;
  border-left: none;
  border-right: none;
  background: #141414;
  border-radius: none;
  height: 2.5rem;
  color: white;
  font-size: 0.9rem;
}

.page-container {
  margin-left: 2rem;
  margin-right: 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;

  flex: 12;
  grid-template-rows: max-content;
}
.report-card {
  background-color: #343434;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  border-radius: 10px;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  font-size: 1rem;
  flex-wrap: wrap;
}

.review-card {
  background-color: #343434;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  margin-top: 0.5rem;
  border-radius: 10px;
  padding: 2rem 2rem 2rem 2rem;
  font-size: 1rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}
.review-card-title {
  margin-top: 0.5rem;

  margin-bottom: 1rem;
  padding-bottom: 0;
  color: #ffff;
  font-weight: 700;
}
.review-card-description {
  font-size: 0.9rem;
  overflow-wrap: break-word;
}
.review-card-id {
  margin-top: 0.5rem;
  font-size: 0.8rem;
}
.review-card-buttons {
  display: flex;
  gap: 1rem;
}
.list-item {
  background-color: #343434;
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  border-radius: 10px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}
.list-item-tiers {
  background-color: #343434;
  height: 4rem;
  width: 100%;
  min-width: 25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  border-radius: 10px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  flex-wrap: nowrap;
  gap: 5rem;
}
.list-item-button {
  margin-left: auto;
}
.title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 4rem;
  color: #f42898;
}
.create-veichle {
  width: fit-content;
  margin-left: auto;
  margin-bottom: 25px;
}
.veichle-flex-info {
  width: 80px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: start;
  margin-right: 20px;
}
.veichle-seats {
  color: #f42898; 
  width: 20px;
  display: block;
}

.btn {
  background-color: #f42898;
  border-radius: 10px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border: 0px;
  height: 2rem;
  line-height: 2rem;
  padding: 0px 2rem;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  width: max-content;
}
.btn:disabled {
  background-color: #343434;
}

.event-form {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.event-form input {
  margin-right: 2rem;
}
.sidebar {
  flex: 1;
  width: 15vw;
  min-width: 15rem;
  height: 100vh;

  z-index: 100;
  background-color: #343434;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  position: sticky;
  transition: all 0.3s ease-in;
  transform: translate(0);
}
.logo-container {
  margin-top: 1rem;
  margin-bottom: 0;
  margin-left: 1.5rem;
}
.sidebar-listitem {
  text-align: left;
  margin-left: 0.5rem;
  height: 2rem;
  justify-content: space-between;
  margin-top: 1rem;
  height: 3rem;
  border-radius: 10px;
  padding: 0 1rem 0 1rem;
  font-size: 1.2rem;
  cursor: pointer;
}

label {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: grid;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  width: 50%;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}
.loginContainer {
  width: 70vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
.loginTitle {
  background-image: url("./assets/logo.png");
  background-color: #141414;
  background-size: contain;

  height: 20rem;
  width: 25rem;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  background-position: center;
}
.loginForm {
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: 20rem;
}
.payment-container {
  margin: 5% 10% 20px 10%;
}
.loginForm input {
  border-bottom: 0.2px solid #ffffff;
  border-top: none;
  border-left: none;
  border-right: none;
  background: #141414;
  border-radius: none;
  height: 2.5rem;
  color: white;
}
.loginButtonContainer {
  margin-top: 2rem;
  justify-self: center;
}

.title {
  margin-bottom: 2rem;
  padding-bottom: 0;
  color: #ffff;
}
.title-secondary {
  margin: 4rem 0px 2rem;
  color: #f42898;
}
.description {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content;
  justify-content: left;
  align-items: center;
  row-gap: 1rem;
}

.bigPageContainer {
  display: flex;
}

.product {
  margin: 4rem;
  margin-bottom: 5rem;
  font-size: 1.5rem;
}
.products-container {
  display: flex;
  flex-wrap: wrap;
}

.tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 50vw;
}

.tag {
  background-color: #343434;
  margin: 0.5rem 0.5rem 0.5rem 0;

  border-radius: 10px;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  display: flex;
  align-items: center;
}
.tag-name {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 5rem;
}
.tag-category {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.tag-form {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 3rem;
}

select {
  background-color: #141414;
  border: 1px solid #ffffff;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  color: #ffffff;
  outline: none;
  border-radius: 5px;
  height: 2.5rem;
}
.remove-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.toggle-sidebar {
  display: none;
}
.form-container {
  margin-bottom: 4rem;
}
.item {
  margin: 3rem 4rem 4rem 0rem;
}
.item-name {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.item-title {
  margin-top: 3rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: #f42898;
}
.item-info {
  font-size: 0.9rem;
  font-weight: 400;
}
.pagination-container {
  display: flex;
  flex-direction: row;
  margin: 1rem 2rem 1rem auto;
  width: max-content;
}
.pagination-item {
  margin: 1rem;
  cursor: pointer;
}
.pagination-active {
  color: #f42898;
  cursor: default;
}

.active {
  color: #f42898;
}

.name {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.item-description {
  margin-bottom: 2em;
}
.form-group {
  display: flex;
  align-items: baseline;
  gap: 2rem;
}

.configuration-input {
  text-align: right;
  margin-right: 0.5rem;
}
.col-1 {
  width: 15rem;
}
.col-2 {
  width: 15rem;
}
.form-control {
  width: 10rem;
}
.col-form-label {
  font-weight: 700;
}
@media (max-width: 800px) {
  html {
    font-size: 100%;
  }
  .sidebar-hide {
    transform: translate(-100%);
    transition: all 0.3s ease-in;
  }
  .toggle-sidebar {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
  .bigPageContainer {
    display: block;
  }
  .sidebar {
    position: fixed;
    width: 70%;
  }
  .event-form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .StripeElement,
  .StripeElementIdeal,
  .StripeElementP24,
  .StripeElementEPS,
  .StripeElementFpx {
    width: 100%;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 2rem;
  }
  .col-form-label {
    margin-bottom: 0;
    margin-top: 4rem;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield;
}
.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 75px;
  margin-bottom: 20px;
}
.filter {
  display: flex;
  flex-direction: row;
  gap: 90px;
  align-items: start;
  justify-content: center;
  margin-top: 25px;
}
.filter-date {
 display: flex;
 gap: 10px;
 align-items: center;
 margin-top: 10px;
}
.filter label {
  font-weight: bold;
  margin-bottom: 5px;
}
.filter input {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}
.user-table th, .user-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}
.title {
margin-bottom: 0px !important;
}
.user-table th {
  background-color: #f42898;
  font-weight: bold;
}
.btn-wrapper {
  display: flex;
  justify-content: center;
}
  .reset-filters {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
}
.reset-icon {
    font-size: 18px;
    color: white;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 50%;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}
.reset-icon:hover {
  background-color: #fdd;
  border-color: white;
}
.btn-custom {
  background-color: #f42898;
  border-radius: 5px;
  padding: 0 12px;
  height: 40px;
  cursor: pointer
}
.custom-label {
  margin-top: 0;
  margin-bottom: 10px !important;
}
.custom-link {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(67, 67, 255);
}
.svg-container{
  cursor: pointer;
}
.state-infos {
  margin-bottom: 17px;
}